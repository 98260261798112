import React from 'react';
import * as animationData from '../lotties/404.json';
import InProgress from '../components/InProgress';
import '../scss/main.scss';


const NotFoundPage = () => {
    return (
        <InProgress
            animationClasses="in-progress__animation in-progress__animation--404"
            subtext="Sorry, we can't find the page you're looking for."
            animationData={animationData}
        />
    )
}

export default NotFoundPage;
